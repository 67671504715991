import React, { useState, useEffect, useCallback, memo, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  ModuleRegistry,
  CellStyleModule,
  ClientSideRowModelModule,
  ColumnApiModule,
  EventApiModule,
  LocaleModule,
  RowApiModule,
  RowSelectionModule,
  RowStyleModule,
  TooltipModule,
  ValidationModule,
  provideGlobalGridOptions,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { fetchInitialGridOptions } from './utils/tableDefinitions'
import { useSettingsContext } from 'pages/Settings/store/settingsContext'
import { setSelection, setUnitOrModuleSelection } from 'pages/Settings/store/actions'
import { ASSIGNMENT_LIST_ITEM_TYPES, ASSIGNMENT_LIST_COLUMN_FIELDS } from 'utils/constants'
// import { sampleRowData } from './utils/tableMockData'

// AG Grid - Mark all grids as using legacy themes
provideGlobalGridOptions({
  theme: 'legacy',
})

// AG Grid modules
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  CellStyleModule,
  ColumnApiModule,
  EventApiModule,
  LocaleModule,
  RowApiModule,
  RowSelectionModule,
  RowStyleModule,
  TooltipModule,
  ValidationModule,
])

const ACTIONABLE_COLUMNS = [
  ASSIGNMENT_LIST_COLUMN_FIELDS.checker,
  ASSIGNMENT_LIST_COLUMN_FIELDS.edit_action,
  ASSIGNMENT_LIST_COLUMN_FIELDS.title,
]

const AssignmentsTable = ({ theme, data, onOpenModule, handleEditUnitOrModule }) => {
  const { t } = useTranslation()
  const {
    assignmentSelection,
    assignmentTagsState,
    assignmentTagsActions,
    assignmentTypes,
    dispatchSettingsAction,
  } = useSettingsContext()

  const selectUnitOrModule = useCallback(
    (selection) => setUnitOrModuleSelection({ dispatchSettingsAction, selection }),
    [dispatchSettingsAction],
  )

  const setAssignmentSelection = useCallback(
    (selection) => setSelection({ dispatchSettingsAction, selection }),
    [dispatchSettingsAction],
  )

  const [gridApi, setGridApi] = useState(null)
  // const [gridColumnApi, setGridColumnApi] = useState(null)
  const [rowData, setRowData] = useState(null)

  /**
   * Hook used to set tabulation callbacks on ag-grid
   * @param {fn} callback
   * @returns memoized function
   */
  function useDynamicCallback(callback) {
    const ref = useRef()
    ref.current = callback
    return useCallback((...args) => ref.current.apply(this, args), [])
  }

  const fetchTypesToPropagate = useCallback(
    () =>
      Object.keys(assignmentTagsState)
        .filter((assignmentType) => assignmentTagsState[assignmentType].propagate)
        .map((key) => ({
          key,
          label: assignmentTypes[key],
          value: assignmentTagsState[key].value,
          propagate: assignmentTagsState[key].propagate,
        })),
    [assignmentTagsState, assignmentTypes],
  )

  const reapplyAssignmentSelectionInTable = useCallback(() => {
    if (!gridApi) return
    const selectedAssignmentsIdentifiers = assignmentSelection.reduce((acc, assignment) => {
      acc.push(assignment.identifier)
      return acc
    }, [])

    gridApi.forEachNode((node) => {
      if (selectedAssignmentsIdentifiers.includes(node.data.identifier)) {
        node.setSelected(true)
      }
    })
  }, [assignmentSelection, gridApi])

  useEffect(() => {
    if (!gridApi || !assignmentTagsState) return
    const typesToPropagate = fetchTypesToPropagate()
    if (typesToPropagate.length === 0) return
    const selectedTypeValues = typesToPropagate.reduce((acc, type) => {
      if (type.value) {
        acc.push(type.key)
      }
      return acc
    }, [])

    gridApi.forEachNode((node) => {
      const shouldBeSelected = selectedTypeValues.includes(node.data.purposeType)
      node.setSelected(shouldBeSelected)
    })
    setAssignmentSelection(gridApi.getSelectedRows())
  }, [gridApi, assignmentTagsState, fetchTypesToPropagate, setAssignmentSelection])

  const onSelectionChange = useCallback(
    /**
     * Event only fires when updating from table because listener is declared
     * inside a useEffect and it occurs after the useEffect that selects-deselects
     * assignments based on tags.
     */
    () => {
      if (!gridApi) return
      setAssignmentSelection(gridApi.getSelectedRows())
      assignmentTagsActions.clearTags({ propagate: false })
    },
    [gridApi, assignmentTagsActions, setAssignmentSelection],
  )

  useEffect(() => {
    if (!gridApi) return
    gridApi.addEventListener('selectionChanged', onSelectionChange)
    return () => {
      if (!gridApi.isDestroyed()) {
        gridApi.removeEventListener('selectionChanged', onSelectionChange)
      }
    }
    /**
     * assignmentTagsState MUST be a dependency because we need the
     * selectionChanged listener to be removed before the useEffect
     * that handles selection/deselection is executed.
     */
  }, [gridApi, onSelectionChange, assignmentTagsState])

  const onTitleClick = useCallback(
    (assignment) => {
      onOpenModule(assignment)
    },
    [onOpenModule],
  )

  const onUnitOrModuleEdit = useCallback(
    (item) => {
      selectUnitOrModule(item)
      handleEditUnitOrModule()
    },
    [selectUnitOrModule, handleEditUnitOrModule],
  )

  const filterCellKeyPress = (e) => {
    if (!(e.event.key === 'Enter')) return
    if (
      e?.column?.colId === 'col_title' &&
      e?.node?.data?.type === ASSIGNMENT_LIST_ITEM_TYPES.assignment
    ) {
      onTitleClick(e.node.data)
    }
    if (
      e?.column?.colId === 'col_edit_action' &&
      e?.node?.data?.type !== ASSIGNMENT_LIST_ITEM_TYPES.assignment
    ) {
      onUnitOrModuleEdit(e.node.data)
    }
    return
  }

  /**
   * This method is used to verify whether an assessment has a parent with a defined start date.
   * Method returns false if there's no inheritance or the parent segment which defines the inheritance.
   * @param { assessment } row
   * @param { ag-grid api} gridApi
   * @returns false or segment(Unit or Module)
   */
  const findStartDateAscendantSegment = (row, gridApi) => {
    if (!gridApi) return
    if (data[row].type === ASSIGNMENT_LIST_ITEM_TYPES.unit) {
      return false
    }
    const parentTarget =
      data[row].type === ASSIGNMENT_LIST_ITEM_TYPES.module
        ? [ASSIGNMENT_LIST_ITEM_TYPES.unit]
        : [ASSIGNMENT_LIST_ITEM_TYPES.module, ASSIGNMENT_LIST_ITEM_TYPES.unit]

    let result = false
    let parentUnitFound = false
    let reverseIndex = row - 1
    while (!parentUnitFound && reverseIndex >= 0) {
      if (parentTarget.includes(data[reverseIndex].type)) {
        // Parent segment.
        result =
          data[reverseIndex].studentAvailability && data[reverseIndex].availableDate
            ? data[reverseIndex]
            : false
        if (data[reverseIndex].type === ASSIGNMENT_LIST_ITEM_TYPES.module) {
          // Remove modules from target to avoid inheriting from wrong module.
          parentTarget.splice(parentTarget.indexOf(ASSIGNMENT_LIST_ITEM_TYPES.module), 1)
        }
        if (data[reverseIndex].type === ASSIGNMENT_LIST_ITEM_TYPES.unit || result) {
          // Stop at first unit level or if start date is found is found.
          parentUnitFound = true
        } else {
          reverseIndex -= 1
        }
      } else {
        // Not parent segment, keep iterating.
        reverseIndex -= 1
      }
    }
    return result
  }

  const fetchGridOptions = useCallback(() => {
    // From i18n
    const columns = {
      // location: t('assignmentTable.columns.location'),
      [ASSIGNMENT_LIST_COLUMN_FIELDS.title]: t('assignmentTable.columns.title'),
      [ASSIGNMENT_LIST_COLUMN_FIELDS.type]: t('assignmentTable.columns.type'),
      [ASSIGNMENT_LIST_COLUMN_FIELDS.max_attempts]: t('assignmentTable.columns.attempts'),
      [ASSIGNMENT_LIST_COLUMN_FIELDS.time_limit]: t('assignmentTable.columns.timeLimit'),
      [ASSIGNMENT_LIST_COLUMN_FIELDS.student_exceptions]: t(
        'assignmentTable.columns.studentExceptions',
      ),
      [ASSIGNMENT_LIST_COLUMN_FIELDS.available_date]: t('assignmentTable.columns.availableDate'),
      [ASSIGNMENT_LIST_COLUMN_FIELDS.due_date]: t('assignmentTable.columns.dueDate'),
    }
    return fetchInitialGridOptions({ columns, t })
  }, [t])

  const onGridReady = (params) => {
    setGridApi(params.api)
    // setGridColumnApi(params.columnApi)
    setRowData(data)
    // setRowData(sampleRowData)
  }

  useEffect(() => {
    setRowData(data)
    setTimeout(() => reapplyAssignmentSelectionInTable(), 250)
  }, [data, reapplyAssignmentSelectionInTable])

  /**
   * This method returns an agGrid tabulation nextCell object if the cell should be actionable
   * and null if it's not actionable.
   * @param { agGrid Column } column
   * @param { int } rowIndex
   * @returns { column, rowIndex } or Null
   */
  const isCellActionable = (column, rowIndex) => {
    let isActionable = null
    const rowData = gridApi.getDisplayedRowAtIndex(rowIndex).data
    const colField = column.colDef.field
    if (
      (colField === ASSIGNMENT_LIST_COLUMN_FIELDS.checker ||
        colField === ASSIGNMENT_LIST_COLUMN_FIELDS.title) &&
      rowData[ASSIGNMENT_LIST_COLUMN_FIELDS.type] === ASSIGNMENT_LIST_ITEM_TYPES.assignment
    ) {
      isActionable = { column, rowIndex }
    }

    if (
      colField === ASSIGNMENT_LIST_COLUMN_FIELDS.edit_action &&
      (rowData[ASSIGNMENT_LIST_COLUMN_FIELDS.type] === ASSIGNMENT_LIST_ITEM_TYPES.unit ||
        rowData[ASSIGNMENT_LIST_COLUMN_FIELDS.type] === ASSIGNMENT_LIST_ITEM_TYPES.module)
    ) {
      isActionable = { column, rowIndex }
    }

    return isActionable
  }

  /**
   * This generator yields actionable agGrid tabulation nextCell object candidates
   * to be evaluated by `isCellActionable`.
   * If table is out of bounds it returns null
   * @param { agGrid Tabulation params object} params
   * @returns { column, rowIndex } or Null
   */
  const findNextActionableCell = function* (params) {
    let nextCell = params.nextCellPosition
    let currentRowIndex = params.previousCellPosition.rowIndex
    const directionIncrement = params.backwards ? -1 : 1
    let nextColumn = nextCell.column
    const renderedRowCount = params.api.getDisplayedRowCount()
    const renderedColumns = params.api.getAllDisplayedColumns()

    let currentColIndex = ACTIONABLE_COLUMNS.findIndex((x) => x === nextCell.column.colDef.field)

    let done = false
    while (!done) {
      let colIndexOutOfBounds =
        currentColIndex > ACTIONABLE_COLUMNS.length - 1 || currentColIndex < 0
      if (colIndexOutOfBounds) {
        const rowIndexOutOfBounds =
          currentRowIndex + directionIncrement > renderedRowCount - 1 ||
          currentRowIndex + directionIncrement < 0
        if (rowIndexOutOfBounds) {
          currentRowIndex = null
        } else {
          currentRowIndex = currentRowIndex + directionIncrement
        }

        nextColumn = directionIncrement === 1 ? renderedColumns[0] : renderedColumns[2]
        currentColIndex = directionIncrement === 1 ? 0 : 2
      } else {
        nextColumn = renderedColumns.find(
          //eslint-disable-next-line
          (col) => col.colDef.field === ACTIONABLE_COLUMNS[currentColIndex],
        )
      }
      if (currentRowIndex !== null) {
        currentColIndex += directionIncrement
        yield { column: nextColumn, rowIndex: currentRowIndex }
      } else {
        done = true
      }
    }

    return null
  }

  /**
   * Custom tab navigation method for ag-grid table.
   * This method prevents tab focus on non actionable cells
   */
  const tabToNextCell = useDynamicCallback((params) => {
    let previousCell = params.previousCellPosition
    let nextCell = params.nextCellPosition

    if (!nextCell) {
      // No next cell, exit table focus
      return
    }
    let nextActionableCell
    const actionableCellGenerator = findNextActionableCell(params)
    while (!nextActionableCell) {
      if (nextCell) {
        nextActionableCell = isCellActionable(nextCell.column, nextCell.rowIndex)
        if (!nextActionableCell) {
          nextCell = actionableCellGenerator.next().value
        }
      } else {
        nextActionableCell = { exit: true }
      }
    }

    if (nextActionableCell.exit) return false // There are no actionable cells left
    const result = {
      rowIndex: nextActionableCell.rowIndex,
      column: nextActionableCell.column,
      floating: previousCell.floating,
    }
    return result
  })

  /**
   * Custom tab navigation method for ag-grid table headers.
   * This method prevents tab focus on column headers as they have no actions.
   * It also focuses on first editable table element once tabbing goes out of bounds.
   */
  const tabToNextHeader = (params) => {
    const columns = params.api.getAllDisplayedColumns()
    const directionIncrement = params.backwards ? -1 : 1
    // Return null to move tabbing above table headers
    if (directionIncrement === -1) return null
    // Find next focusable cell based on row type
    const isAssignment =
      gridApi.getDisplayedRowAtIndex(0).data.type === ASSIGNMENT_LIST_ITEM_TYPES.assignment
    const targetColId = isAssignment ? 'col_checker' : 'col_edit_action'
    const nextCol = columns.find((col) => col.colId === targetColId)
    return { headerRowIndex: -1, column: nextCol }
  }

  return (
    <div
      className="ag-theme-alpine"
      style={{
        height: `${37 + 56 * 7}px`, // 7 rows
        width: '100%',
      }}
    >
      <AgGridReact
        gridOptions={fetchGridOptions()}
        rowData={rowData}
        onGridReady={onGridReady}
        onCellKeyPress={filterCellKeyPress}
        tabToNextCell={tabToNextCell}
        tabToNextHeader={tabToNextHeader}
        context={{
          onTitleClick,
          onUnitOrModuleEdit,
          findStartDateAscendantSegment,
        }}
        className={`assignment-table ${theme.vstui.color.primary.name}`}
      />
    </div>
  )
}

export default memo(withTheme(AssignmentsTable))

AssignmentsTable.propTypes = {
  /**
   * Theme object used for style customization.
   */
  theme: PropTypes.object,
  /**
   * Array of assignments.
   */
  data: PropTypes.array,
  /**
   * onOpenModule
   */
  onOpenModule: PropTypes.func,
  /**
   * on unit or module edit button click
   */
  handleEditUnitOrModule: PropTypes.func,
}

AssignmentsTable.defaultProps = {
  theme: {},
  data: [],
  onOpenModule: () => {},
  handleEditUnitOrModule: () => {},
}
